import React from 'react'
import { GlobalStyles } from 'twin.macro'
import Header from '@/components/organisms/Header'
import Footer from '@/components/organisms/Footer'
import '@fontsource/noto-sans-jp'

const Layout: React.FC = ({ children, ...rest }) => {
  return (
    <div {...rest} tw="font-family['Noto Sans JP'] text-gray-800">
      <GlobalStyles />
      <Header />
      <main tw="bg-gray-100">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
