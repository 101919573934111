import React, { useState } from 'react'
import { Link } from 'gatsby'
import HamburgerMenu from 'react-hamburger-menu'
import tw, { css } from 'twin.macro'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'

const Header: React.FC = () => {
  const [isOpen, toggle] = useState(false)
  return (
    <header tw=" fixed w-full top-0 z-50 bg-gray-800 shadow-md">
      <nav tw="flex items-center justify-between py-4 px-8 md:px-12">
        <div tw="flex justify-between items-center">
          <div tw="tracking-widest text-2xl font-bold text-white md:text-3xl">
            <Link to="/">TakeLab</Link>
          </div>
        </div>
        <HamburgerMenu
          isOpen={isOpen}
          menuClicked={() => toggle(!isOpen)}
          color="white"
          width={30}
          height={20}
          tw="z-50 cursor-pointer"
        />
        <nav
          css={css(
            isOpen ? tw`right-0` : tw`lg:-right-1/4 -right-3/4`,
            tw`absolute bg-gray-700 bg-opacity-80 lg:w-1/4 w-3/4 h-screen top-0 duration-500 flex justify-center flex-col items-center`,
          )}
        >
          <Link
            to="/biography/"
            css={ParagraphStyle(tw`text-white block mt-4`)}
          >
            biography
          </Link>
          <Link to="/works/" css={ParagraphStyle(tw`text-white block mt-4`)}>
            works
          </Link>
          <Link to="/posts/" css={ParagraphStyle(tw`text-white block mt-4`)}>
            blogs
          </Link>
        </nav>
      </nav>
    </header>
  )
}

export default Header
