import React from 'react'
import 'twin.macro'

const Footer: React.FC = () => {
  return (
    <footer tw="bg-gray-800 py-6">
      <p tw="text-center text-white">
        <small lang="en">©️{new Date().getFullYear()} TakeLab</small>
      </p>
    </footer>
  )
}

export default Footer
